import { useState } from "react";

const Hero = () => {
  const [hoverTxtState, setHoverTxtState] = useState("");

  return (
    <div id="home" className="Hero">
      <div className="hover-text">
        {hoverTxtState !== "" ? hoverTxtState : <span>&nbsp;</span>}
      </div>
      <div className="hero-text">
        <img
          onMouseEnter={() => setHoverTxtState("Thermochromic Crystals H8D")}
          onMouseLeave={() => setHoverTxtState("")}
          src="./assets/k-active.png"
          alt=""
        />
        <img
          onMouseEnter={() => setHoverTxtState("LED Hair String H8D")}
          onMouseLeave={() => setHoverTxtState("")}
          src="./assets/r-active.png"
          alt=""
        />
        <img
          onMouseEnter={() => setHoverTxtState("Roses n Moss H8D")}
          onMouseLeave={() => setHoverTxtState("")}
          src="./assets/w-active.png"
          alt=""
        />
        <img
          onMouseEnter={() => setHoverTxtState("LED Circular Disks H8D")}
          onMouseLeave={() => setHoverTxtState("")}
          src="./assets/n-active.png"
          alt=""
        />
      </div>
      <div className="mint-button">
        <a href="https://mint.krwn.studio" className="firstscreen__btn">
          <svg className="btn-svg-outline" width="229" height="52" viewBox="0 0 229 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="layer1" d="M201.273 46.4598L218.409 23.7303H225L203.909 51.7303H167L171.614 46.4598H201.273Z"></path>
            <path className="layer2" d="M38.1667 0H178.826L173.68 5.57491H194.264L199.839 0H229L197.266 39.4532H141.517L147.521 33.4494H128.652L122.219 39.4532H54.0337L60.0375 33.4494H40.3109L34.3071 39.4532H0L38.1667 0Z"></path>
            <text fontFamily="Pattanakarn" fontSize="16" x="50%" y="30%" dy=".3em" textAnchor="middle" alignmentBaseline="middle">MINT</text>
          </svg>
        </a>
      </div>
      <div className="smokes">
        <img src="./assets/green-smoke.png" alt="" />
        <img src="./assets/green-smoke.png" alt="" />
      </div>
    </div>
  );
};

export default Hero;
