import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Navigation, Autoplay } from "swiper";

const Collection = () => {
  return (
    <div id="collection" className="collectionMain">
      {/* <div className="wrapper"> */}
      <div className="verticalSliderMain">
        <Swiper
          direction={"vertical"}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="verticalSlider copperColor"
        >
          <SwiperSlide>
            <img src="./assets/collection-videos/copper/1.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/collection-videos/copper/2.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/collection-videos/copper/3.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/collection-videos/copper/4.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/collection-videos/copper/5.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="./assets/collection-videos/copper/6.jpg" alt="" />
          </SwiperSlide>
        </Swiper>
        <Swiper
          direction={"vertical"}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="verticalSlider silverColor"
        >
          <SwiperSlide>
            <video
              src="./assets/collection-videos/silver/hoodie-32-blue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/silver/hoodie-59-red.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/silver/hoodie-21-darkgree.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/silver/hoodie-32-lightblue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/silver/hoodie-21-purple.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/silver/hoodie-59-yellow.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
        </Swiper>
        <Swiper
          direction={"vertical"}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="verticalSlider goldColor"
        >
          <SwiperSlide>
            <video
              src="./assets/collection-videos/gold/hoodie-12-yellow.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/gold/hoodie-12-purple.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/gold/hoodie-27-yellow.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/gold/hoodie-46-lightblue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/gold/hoodie-51-red.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/gold/hoodie-51-white.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
        </Swiper>
        <Swiper
          direction={"vertical"}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="verticalSlider platinumColor"
        >
          <SwiperSlide>
            <video
              src="./assets/collection-videos/platinum/hoodie-36-blue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/platinum/hoodie-35-lightblue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/platinum/hoodie-63-white.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/platinum/hoodie-38-orange.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/platinum/hoodie-38-blue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/platinum/hoodie-36-yellow.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
        </Swiper>
        <Swiper
          direction={"vertical"}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="verticalSlider diamondColor"
        >
          <SwiperSlide>
            <video
              src="./assets/collection-videos/diamond/hoodie-61-white.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/diamond/hoodie-45-lightBlue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/diamond/hoodie-33-blue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/diamond/hoodie-66-lightblue.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/diamond/hoodie-45-red.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
          <SwiperSlide>
            <video
              src="./assets/collection-videos/diamond/hoodie-61-orange.mp4"
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Collection;
