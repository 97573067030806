import "../styles/utilities.scss";

const Utilities = () => {
  return (
    <div id="utilities" className="Utilities">
      <div className="wrapper">
        <h2>Utilities</h2>
        <div className="grid-main">
          <div className="grid-col">
            <div className="grid copper">
              <div className="grid-header">
                <h3>Copper</h3>
                <div className="grid-num">8000</div>
              </div>
              <div className="grid-body">
                <p>
                  Tickets for <em>events</em>:
                </p>
                <ul>
                  <li>
                    Exclusive runways (including guided tours of high-end and
                    emerging fashion shows, backstage accesses and meet &amp;
                    greets)
                  </li>
                  <li>Design weeks</li>
                  <li>Art shows</li>
                  <li>Film festivals</li>
                  <li>Travel getaways</li>
                  <li>Web3/NFT/Blockchain events and more…</li>
                </ul>
                <p>
                  For <em>creatives</em> and <em>entrepreneurs</em>&nbsp;we also
                  offer our facilities to help build projects, including:
                </p>
                <ul>
                  <li>3D Design and AR/VR solutions</li>
                  <li>Blockchain/NFT Technology</li>
                  <li>NFC Development</li>
                  <li>Marketing</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="grid-col">
            <div className="grid silver">
              <div className="grid-header">
                <h3>Silver</h3>
                <div className="grid-num">410</div>
              </div>
              <div className="grid-body">
                <ul>
                  <li>
                    All <em>Copper</em> Utility
                  </li>
                  <li>Personalised AR Filters of the minted H8D</li>
                  <li>
                    You can now wear your garment and take pictures through
                    social media. Make sure to tag @krwn_studio if you decide to
                    share any of your dope poses!
                  </li>
                  <li>
                    All AR links are private to the holder, enjoy your lux
                    clothing without worry.
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid gold">
              <div className="grid-header">
                <h3>Gold</h3>
                <div className="grid-num">290</div>
              </div>
              <div className="grid-body">
                <ul>
                  <li>
                    All <em>Silver</em> + <em>Copper</em> Utility
                  </li>
                  <li>NFT Dressing</li>
                  <li>Photo Dressing</li>
                  <li>Available on-request through Discord</li>
                  <li>
                    You can dress your favourite IG photo or NFT profile picture
                    in our fashion!
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="grid-col">
            <div className="grid platinum">
              <div className="grid-header">
                <h3>Platinum</h3>
                <div className="grid-num">140</div>
              </div>
              <div className="grid-body">
                <ul>
                  <li>
                    All <em>Gold</em> + <em>Silver</em> + <em>Copper</em>{" "}
                    Utility
                  </li>
                  <li>Gaming wearability</li>
                  <li>Wearable across 15+ Metaverses and Virtual Games</li>
                  <li>More platforms onboarding soon…</li>
                </ul>
              </div>
            </div>
            <div className="grid diamond">
              <div className="grid-header">
                <h3>Diamond</h3>
                <div className="grid-num">51</div>
              </div>
              <div className="grid-body">
                <ul>
                  <li>
                    All <em>Platinum</em> + <em>Gold</em> + <em>Silver</em> +
                    Copper Utility
                  </li>
                  <li>
                    Priority access to special events, airdrops, phygitals,
                    giveaways and more…
                  </li>
                  <li>
                    Test out our new and upcoming projects before anyone else
                  </li>
                  <li>
                    Participate in KRWN’s decision making processes, and help to
                    govern the brand
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Utilities;
