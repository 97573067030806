import { useState } from "react";

const Mindmap = () => {
  const [clicked, setClicked] = useState(false);

  const mindmapData = [
    {
      title: "Vision & Values",
      description: (
        <>
          <p>
            Moving between the digital &amp; physical realms: we are building a
            community of creatives who aren&apos;t afraid to push boundaries,
            communicate boldly or make an impact.
            <br />
            We visualize creating the largest decentralized fashion house for
            the Metaverse. Built and owned by the community.
          </p>
          <ol>
            <li>
              <strong>Community first:</strong> Everything we do is for the
              believers of our brand. As we grow & build together, so do our
              successes. We pave the way for fashion enthusiasts to co-exist in
              a healthy and innovative environment, exploring the limits of
              imagination.
            </li>
            <li>
              <strong>Dope s*** 4 u:</strong> We will organize holder-only
              events ranging from fun yacht parties in Dubai to expert panel
              speeches in Milan. By coming together IRL, we get to explore our
              community and have a tangible experience of the brand. KRWN is
              brave, creative and original, this will be reflected in all the
              events we host together.
            </li>
            <li>
              <strong>Trust our process:</strong> Many great revolutions take
              time. Our brand has seen extensive growth in the last few months,
              and this is only the start. As we share each milestone with our
              community, we are making incremental progress towards creating the
              innovative future of fashion for our creative economy.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "Community",
      description: (
        <>
          <p>
            <strong>Full-time mods:</strong> As our community grows, so does a
            need for management. Mods help to grow and protect the people who
            believe in KRWN. We believe that our community comes first in
            everything we do, which is why our moderator&apos;s team must
            reflect our values and treasured as valuable assets of the KRWN
            community.
          </p>
          <p>
            <strong>Community Spaces:</strong> Casual chats, speeches, panel
            talks, or even heartfelt stories. KRWN will focus on creating a
            free, safe, and open space for all its members on Twitter and
            Discord. These forums will bring us closer together and keep us
            connected regardless of where we are in the world.
          </p>
          <p>
            <strong>Partnerships &amp; Collaborations:</strong> One of our
            favorite parts of being in the web 3 space, is the opportunity to
            work with creators that have a similar vision and aesthetic as KRWN.
            So far, we have worked with known fashion innovation magazines,
            participated in exhibitions during NYFW and organized fashion show
            ticket giveaways due to our partners and collaborators. We wish to
            continue offering our members unique experiences as a result of our
            work with other amazing brands.
          </p>
        </>
      ),
    },
    {
      title: "Create with KRWN",
      description: (
        <>
          <p>
            By holding an established presence among the web 3 and digital
            fashion community: we invite artists to co-create fashion designs
            together with KRWN! As our creative community expands so does our
            vision of what could be possible. KRWN is a fashion brand for the
            people, hence we wish to work together with great minds to create
            customizable and truly unique designs available to ONLY YOU!
          </p>
          <p>
            KRWN will also organize a mix-and-matching platform for members to
            pick textures, garments and colors to generate a fashion piece that
            is a 1/1 clothing item created by them.
          </p>
        </>
      ),
    },
    {
      title: "New Drops",
      description: (
        <>
          <p>
            After our debut collection of hoodies, KRWN will be offering other
            product lines in each of our new seasons. As we continue to bring
            high-end streetwear collections into the web 3 space, members can
            expect new drops in perpetuity.
          </p>
          <p>
            We are here to spread a movement. Stay connected with us to help
            direct this digital fashion revolution.
          </p>
        </>
      ),
    },
    {
      title: "Metaverse",
      description: (
        <>
          <p>
            Currently in negotiations with major metaverse platforms, we are
            aiming to offer our holders the unique experience of dressing their
            avatars in KRWN garments.
          </p>
          <p>
            Our choice of surpassing the creation of physical clothing, stems
            from our deep dedication to sustainability. By offering our members
            the opportunity to wear KRWN collections in the Metaverse, it is an
            added utility that will increase the brands reach to another level
            of the digital realm.
          </p>
          <p>More on this coming soon.</p>
        </>
      ),
    },
    {
      title: "Decentralization",
      description: (
        <>
          <p>
            Experimentation with DAOs is one of the most exciting parts of web3
            and crypto today. We must start small and build the channels for
            governing structures while working on various areas that need the
            most improvement.
          </p>
          <p>
            <strong>$KRWN</strong>
            <br />
            As we brainstorm ideas - a governance KRWN coin can be issued for
            our community to have a say in direction of the brand.
          </p>
        </>
      ),
    },
  ];

  const toggleGridActive = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <div id="mindmap" className="Mindmap">
      <div className="wrapper">
        <div className="mindmap-grid">
          {mindmapData.map((item, index) => {
            return (
              <div
                className={
                  clicked === index ? "grid-item active-grid" : "grid-item"
                }
                onClick={() => toggleGridActive(index)}
                key={index}
              >
                <div className="close-grid">&times;</div>
                <div className="serial">0{index + 1}</div>
                <div className="title">{item.title}</div>
                {clicked === index ? (
                  <div className="description">{item.description}</div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Mindmap;
