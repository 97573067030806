import "./App.scss";

import Header from "./components/Header";

import Hero from "./screens/Hero";
import Introduction from "./screens/Introduction";
import Mission from "./screens/Mission";
import About from "./screens/About";
import Utilities from "./screens/Utilities";
// import Ourstory from "./screens/Ourstory";
import Mindmap from "./screens/Mindmap";
import Socials from "./screens/Socials";
import Collection from "./screens/Collection";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Introduction />
      <Collection />
      <Mission />
      <About />
      <Utilities />
      {/* <Ourstory /> */}
      <Mindmap />
      <Socials />
    </div>
  );
}

export default App;
