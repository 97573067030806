import React, { Suspense, useRef, useEffect, useState } from "react";

// swiper slider
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

// three.js
import { useGLTF, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

function HoodieGreenLines({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("models/hoodie_green_lines.gltf");

  return (
    <group ref={group} {...props} dispose={null} scale={[0.06, 0.06, 0.06]}>
      <mesh
        geometry={nodes["Hoodie-luminance"].geometry}
        material={materials.luminance}
      />
      <mesh
        geometry={nodes["Hoodie-base"].geometry}
        material={materials.base}
      />
      <mesh
        geometry={nodes["Hoodie-logo"].geometry}
        material={materials.logo}
      />
    </group>
  );
}

function HoodieRips(props) {
  const { nodes, materials } = useGLTF("models/hoodie_rips.gltf");
  return (
    <group {...props} dispose={null} position={[0, -7.8, 0]} scale={3.2}>
      <mesh
        geometry={nodes.Hoodie_Base004.geometry}
        material={materials.Orange}
      />
      <mesh
        geometry={nodes.King_symbol004.geometry}
        material={materials.Glow}
        position={[0, 2.69, 0.25]}
        rotation={[-0.36, 0.08, 0.01]}
        scale={1.08}
      />
    </group>
  );
}

function HoodieWings(props) {
  const { nodes, materials } = useGLTF("models/hoodie_wings.gltf");
  return (
    <group {...props} dispose={null} position={[0, -7, 0]} scale={2.3}>
      <mesh
        geometry={nodes.MODEL_HOODIE_2008.geometry}
        material={materials.LightBlue}
      />
      <mesh
        geometry={nodes.MODEL_HOODIE_2008_1.geometry}
        material={materials.Gold}
      />
      <mesh
        geometry={nodes.King_symbol003.geometry}
        material={materials["King symbol"]}
      />
      <mesh
        geometry={nodes.Wing006.geometry}
        material={materials["Wings blue"]}
        position={[0.06, 2.64, -0.27]}
        rotation={[Math.PI / 2, 0, -0.89]}
      />
      <mesh
        geometry={nodes.Wing007.geometry}
        material={materials["Wings blue"]}
        position={[0.06, 2.64, -0.27]}
        rotation={[Math.PI / 2, 0, -2.6]}
      />
    </group>
  );
}

function HoodieCrwn({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("models/hoodie_crwn.gltf");
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={[0, -7.5, 0]}
      scale={3.1}
    >
      <group name="Scene">
        <group name="Hoodie_Base005">
          <mesh
            name="MODEL_HOODIE_2016"
            geometry={nodes.MODEL_HOODIE_2016.geometry}
            material={materials["Words Orange"]}
          />
          <mesh
            name="MODEL_HOODIE_2016_1"
            geometry={nodes.MODEL_HOODIE_2016_1.geometry}
            material={materials["Inv Orange"]}
          />
        </group>
      </group>
    </group>
  );
}

const SceneHoodieGreenLines = () => {
  return (
    <>
      <pointLight intensity={0.1} position={[0, 25, 20]} />
      <pointLight intensity={0.1} position={[0, 0, -20]} />
      <pointLight intensity={0.1} position={[0, 10, 0]} />
      <HoodieGreenLines />
    </>
  );
};

const SceneHoodieRips = () => {
  return (
    <>
      <pointLight intensity={0.3} position={[0, 25, 20]} />
      <pointLight intensity={0.2} position={[0, 0, -20]} />
      <pointLight intensity={0.8} position={[0, 10, 0]} />
      <ambientLight intensity={0.3} />
      <ambientLight intensity={0.3} position={[0, -10, 0]} />
      <HoodieRips />
    </>
  );
};

const SceneHoodieWings = () => {
  return (
    <>
      <pointLight intensity={0.3} position={[0, 25, 20]} />
      <pointLight intensity={0.2} position={[0, 0, -20]} />
      <pointLight intensity={0.8} position={[0, 10, 0]} />
      <HoodieWings />
    </>
  );
};

const SceneHoodieCrwn = () => {
  return (
    <>
      <pointLight intensity={1} position={[0, 25, 20]} />
      <pointLight intensity={0.8} position={[0, 0, -20]} />
      {/* <pointLight intensity={1.8} position={[0, 10, 0]} /> */}
      <ambientLight intensity={0.5} />
      <HoodieCrwn />
    </>
  );
};

const Introdcution = () => {
  const [textBubble1, setTextBubble1] = useState(false);
  const [textBubble2, setTextBubble2] = useState(false);
  const [textBubble3, setTextBubble3] = useState(false);
  const [textBubble4, setTextBubble4] = useState(false);
  const [textBubble5, setTextBubble5] = useState(false);
  const [textBubble6, setTextBubble6] = useState(false);
  const [textBubble7, setTextBubble7] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      window.scrollY > (window.innerWidth > 600 ? 1000 : 700)
        ? setTextBubble1(true)
        : setTextBubble1(false);
      window.scrollY > (window.innerWidth > 600 ? 1200 : 900)
        ? setTextBubble2(true)
        : setTextBubble2(false);
      window.scrollY > (window.innerWidth > 600 ? 1400 : 1100)
        ? setTextBubble3(true)
        : setTextBubble3(false);
      window.scrollY > (window.innerWidth > 600 ? 1600 : 1300)
        ? setTextBubble4(true)
        : setTextBubble4(false);
      window.scrollY > (window.innerWidth > 600 ? 1800 : 1500)
        ? setTextBubble5(true)
        : setTextBubble5(false);
      window.scrollY > (window.innerWidth > 600 ? 2000 : 1700)
        ? setTextBubble6(true)
        : setTextBubble6(false);
      window.scrollY > (window.innerWidth > 600 ? 2200 : 1900)
        ? setTextBubble7(true)
        : setTextBubble7(false);
    };
  });

  return (
    <div id="introduction" className="Introdcution">
      <div className="wrapper">
        <div className="small-heading">
          Season 0 <span>"NEONIZE"</span>
        </div>
        <h1>
          Digital fashion house for the <span>Metaverse.</span>
        </h1>
        <div className="hoodie-slider">
          <div className="in-hoodie-slider">
            <Swiper
              navigation={true}
              modules={[Pagination, Navigation]}
              className={textBubble1 ? "hide-navigators" : ""}
              allowTouchMove={false}
            >
              <SwiperSlide>
                <div className="hoodie-model-main">
                  <div className="hoodie-model">
                    <Canvas>
                      <Suspense fallback={null}>
                        <SceneHoodieGreenLines />
                        <OrbitControls
                          enableZoom={false}
                          enablePan={false}
                          minPolarAngle={Math.PI / 2.5}
                          maxPolarAngle={Math.PI / 2.55}
                        />
                      </Suspense>
                    </Canvas>
                  </div>
                  <div className="text-bubbles">
                    <div
                      className={
                        textBubble3
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Each hoodie in our collection is called H8D or H8Dz
                      [H-U-D], the number 8 symbolising 8891 total pieces in the
                      collection
                    </div>

                    <div
                      className={
                        textBubble7
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      8000 of our H8Dz are membership accesses, the remaining
                      891 are digital fashion collectibles with wearability
                      benefits. See below on utility breakdown.
                    </div>

                    <div
                      className={
                        textBubble4
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN releases collections in seasons. Our debut collection
                      falls under SEASON 0 and titled "NEONIZE" for our strong
                      emphasis on bright colorways
                    </div>

                    <div
                      className={
                        textBubble1
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN places priority on 3 fundamentals of design:
                      Imagination Creativity and Innovation
                    </div>

                    <div
                      className={
                        textBubble6
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Season 0 has 5 rarity levels:
                      <br />
                      <br />
                      <strong>Copper:</strong> AI & Code Generated
                      <br />
                      <strong>Silver:</strong> 2D Patterns
                      <br />
                      <strong>Gold:</strong> 3D applications
                      <br />
                      <strong>Platinum:</strong> Moving designs
                      <br />
                      <strong>Diamond:</strong> A crown mystery
                    </div>

                    <div
                      className={
                        textBubble5
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Every season will consist of different product lines, each
                      with their own theme, and slowly building up the
                      repertoire of KRWN fashion designs.
                    </div>

                    <div
                      className={
                        textBubble2
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Hoodies were chosen for the debut collection for its
                      gender neutrality, common usage and symbolism in the
                      streetwear community
                    </div>
                  </div>
                </div>
                <div className="hoodie-name">Laser Beams H8D</div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="hoodie-model-main">
                  <div className="hoodie-model">
                    <Canvas>
                      <Suspense fallback={null}>
                        <SceneHoodieRips />
                        <OrbitControls
                          enableZoom={false}
                          enablePan={false}
                          minPolarAngle={Math.PI / 2.5}
                          maxPolarAngle={Math.PI / 2.55}
                        />
                      </Suspense>
                    </Canvas>
                  </div>
                  <div className="text-bubbles">
                    <div
                      className={
                        textBubble3
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Each hoodie in our collection is called H8D or H8Dz
                      [H-U-D], the number 8 symbolising 8891 total pieces in the
                      collection
                    </div>

                    <div
                      className={
                        textBubble7
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      8000 of our H8Dz are membership accesses, the remaining
                      891 are digital fashion collectibles with wearability
                      benefits. See below on utility breakdown.
                    </div>

                    <div
                      className={
                        textBubble4
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN releases collections in seasons. Our debut collection
                      falls under SEASON 0 and titled "NEONIZE" for our strong
                      emphasis on bright colorways
                    </div>

                    <div
                      className={
                        textBubble1
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN places priority on 3 fundamentals of design:
                      Imagination Creativity and Innovation
                    </div>

                    <div
                      className={
                        textBubble6
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Season 0 has 5 rarity levels:
                      <br />
                      <br />
                      <strong>Copper:</strong> AI & Code Generated
                      <br />
                      <strong>Silver:</strong> 2D Patterns
                      <br />
                      <strong>Gold:</strong> 3D applications
                      <br />
                      <strong>Platinum:</strong> Moving designs
                      <br />
                      <strong>Diamond:</strong> A crown mystery
                    </div>

                    <div
                      className={
                        textBubble5
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Every season will consist of different product lines, each
                      with their own theme, and slowly building up the
                      repertoire of KRWN fashion designs.
                    </div>

                    <div
                      className={
                        textBubble2
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Hoodies were chosen for the debut collection for its
                      gender neutrality, common usage and symbolism in the
                      streetwear community
                    </div>
                  </div>
                </div>
                <div className="hoodie-name">Denimize H8D</div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="hoodie-model-main">
                  <div className="hoodie-model">
                    <Canvas>
                      <Suspense fallback={null}>
                        <SceneHoodieWings />
                        <OrbitControls
                          enableZoom={false}
                          enablePan={false}
                          minPolarAngle={Math.PI / 2.5}
                          maxPolarAngle={Math.PI / 2.55}
                        />
                      </Suspense>
                    </Canvas>
                  </div>
                  <div className="text-bubbles">
                    <div
                      className={
                        textBubble3
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Each hoodie in our collection is called H8D or H8Dz
                      [H-U-D], the number 8 symbolising 8891 total pieces in the
                      collection
                    </div>

                    <div
                      className={
                        textBubble7
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      8000 of our H8Dz are membership accesses, the remaining
                      891 are digital fashion collectibles with wearability
                      benefits. See below on utility breakdown.
                    </div>

                    <div
                      className={
                        textBubble4
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN releases collections in seasons. Our debut collection
                      falls under SEASON 0 and titled "NEONIZE" for our strong
                      emphasis on bright colorways
                    </div>

                    <div
                      className={
                        textBubble1
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN places priority on 3 fundamentals of design:
                      Imagination Creativity and Innovation
                    </div>

                    <div
                      className={
                        textBubble6
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Season 0 has 5 rarity levels:
                      <br />
                      <br />
                      <strong>Copper:</strong> AI & Code Generated
                      <br />
                      <strong>Silver:</strong> 2D Patterns
                      <br />
                      <strong>Gold:</strong> 3D applications
                      <br />
                      <strong>Platinum:</strong> Moving designs
                      <br />
                      <strong>Diamond:</strong> A crown mystery
                    </div>

                    <div
                      className={
                        textBubble5
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Every season will consist of different product lines, each
                      with their own theme, and slowly building up the
                      repertoire of KRWN fashion designs.
                    </div>

                    <div
                      className={
                        textBubble2
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Hoodies were chosen for the debut collection for its
                      gender neutrality, common usage and symbolism in the
                      streetwear community
                    </div>
                  </div>
                </div>
                <div className="hoodie-name">Winged Beast H8D</div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="hoodie-model-main">
                  <div className="hoodie-model">
                    <Canvas>
                      <Suspense fallback={null}>
                        <SceneHoodieCrwn />
                        <OrbitControls
                          enableZoom={false}
                          enablePan={false}
                          minPolarAngle={Math.PI / 2.5}
                          maxPolarAngle={Math.PI / 2.55}
                        />
                      </Suspense>
                    </Canvas>
                  </div>
                  <div className="text-bubbles">
                    <div
                      className={
                        textBubble3
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Each hoodie in our collection is called H8D or H8Dz
                      [H-U-D], the number 8 symbolising 8891 total pieces in the
                      collection
                    </div>

                    <div
                      className={
                        textBubble7
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      8000 of our H8Dz are membership accesses, the remaining
                      891 are digital fashion collectibles with wearability
                      benefits. See below on utility breakdown.
                    </div>

                    <div
                      className={
                        textBubble4
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN releases collections in seasons. Our debut collection
                      falls under SEASON 0 and titled "NEONIZE" for our strong
                      emphasis on bright colorways
                    </div>

                    <div
                      className={
                        textBubble1
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      KRWN places priority on 3 fundamentals of design:
                      Imagination Creativity and Innovation
                    </div>

                    <div
                      className={
                        textBubble6
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Season 0 has 5 rarity levels:
                      <br />
                      <br />
                      <strong>Copper:</strong> AI & Code Generated
                      <br />
                      <strong>Silver:</strong> 2D Patterns
                      <br />
                      <strong>Gold:</strong> 3D applications
                      <br />
                      <strong>Platinum:</strong> Moving designs
                      <br />
                      <strong>Diamond:</strong> A crown mystery
                    </div>

                    <div
                      className={
                        textBubble5
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Every season will consist of different product lines, each
                      with their own theme, and slowly building up the
                      repertoire of KRWN fashion designs.
                    </div>

                    <div
                      className={
                        textBubble2
                          ? "text-bubble show-text-bubble"
                          : "text-bubble"
                      }
                    >
                      Hoodies were chosen for the debut collection for its
                      gender neutrality, common usage and symbolism in the
                      streetwear community
                    </div>
                  </div>
                </div>
                <div className="hoodie-name">KRWN Inverted</div>
              </SwiperSlide>
            </Swiper>
          </div>
          {!textBubble1 && (
            <div className="eight91-unique-items">
              <img src="./assets/891-unique-items.png" alt="" />
              <p>
                Our debut limited collection of 8891 pieces: each H8D is a
                completely unique piece, exclusively made available to you.
              </p>
            </div>
          )}
        </div>
        <div className="gap">
          <div className="text-bubbles" data-aos="fade-up">
            <div
              className={
                textBubble1 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              Each hoodie in our collection is called H8D or H8Dz [H-U-D], the
              number 8 symbolising 8891 total pieces in the collection
            </div>

            <div
              className={
                textBubble2 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              8000 of our H8Dz are membership accesses, the remaining 891 are
              digital fashion collectibles with wearability benefits. See below
              on utility breakdown.
            </div>

            <div
              className={
                textBubble3 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              KRWN releases collections in seasons. Our debut collection falls
              under SEASON 0 and titled "NEONIZE" for our strong emphasis on
              bright colorways
            </div>

            <div
              className={
                textBubble4 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              KRWN places priority on 3 fundamentals of design: Imagination
              Creativity and Innovation
            </div>

            <div
              className={
                textBubble5 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              Season 0 has 5 rarity levels:
              <br />
              <br />
              <strong>Copper:</strong> AI & Code Generated
              <br />
              <strong>Silver:</strong> 2D Patterns
              <br />
              <strong>Gold:</strong> 3D applications
              <br />
              <strong>Platinum:</strong> Moving designs
              <br />
              <strong>Diamond:</strong> A crown mystery
            </div>

            <div
              className={
                textBubble6 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              Every season will consist of different product lines, each with
              their own theme, and slowly building up the repertoire of KRWN
              fashion designs.
            </div>

            <div
              className={
                textBubble7 ? "text-bubble show-text-bubble" : "text-bubble"
              }
            >
              Hoodies were chosen for the debut collection for its gender
              neutrality, common usage and symbolism in the streetwear community
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introdcution;
