import { useEffect, useState } from "react";

const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [shouldHeaderSticky, setShouldHeaderSticky] = useState(false);

  const menuHandle = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setShouldHeaderSticky(true);
      } else {
        setShouldHeaderSticky(false);
      }
    });
  });

  return (
    <header className={shouldHeaderSticky ? "sticky-header" : ""}>
      <div className="wrapper-fullwidth">
        <div className="logo">
          <a href="#home">
            <img src="./assets/krwn-studio-logo-dark.png" alt="" />
          </a>
        </div>
        <div className="menu-icon" onClick={() => menuHandle()}>
          {isMenuOpened ? (
            <img src="./assets/close-icon.png" alt="" />
          ) : (
            <img src="./assets/hamburger-menu.png" alt="" />
          )}
        </div>
        <nav className={isMenuOpened ? "mobile-menu" : ""}>
          <a href="#home" onClick={() => setIsMenuOpened(false)}>
            Home
          </a>
          <a href="#introduction" onClick={() => setIsMenuOpened(false)}>
            Introduction
          </a>
          <a href="#collection" onClick={() => setIsMenuOpened(false)}>
            Collection
          </a>
          <a href="#mission" onClick={() => setIsMenuOpened(false)}>
            Mission
          </a>
          <a href="#about" onClick={() => setIsMenuOpened(false)}>
            About us
          </a>
          <a href="#utilities" onClick={() => setIsMenuOpened(false)}>
            Utilities
          </a>
          <a href="#mindmap" onClick={() => setIsMenuOpened(false)}>
            Mindmap
          </a>
          <a href="#socials" onClick={() => setIsMenuOpened(false)}>
            Socials
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
