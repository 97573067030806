const About = () => {
  return (
    <div id="about" className="About">
      <div className="wrapper">
        <h2>About us</h2>
        <p>We are a creative, democratic, innovative and sustainable brand</p>
        <div className="half-half">
          <div>
            <p>
              KRWN believes in the value of self-expression. All our designs are
              made to maximize a person&apos;s ability to imagine themselves as
              their alternate persona and make your “digital twin” come to life.
              Digital fashion offers everyone the opportunity to surpass any
              physical limitations of real life. Gravity doesn&apos;t exist,
              liquids are wearable and interterritorial materials are
              attainable. Your imagination is the only limit.
            </p>
            <p>
              As a community, we want to bring a new future that belongs to
              creators. Artists will become the experts of the influential
              digital fashion world, determining the value of creativity that
              surpasses conventional beliefs. Through collaborations, we are
              strong in our belief of working together to create an open,
              welcoming, and innovative future for the fashion world.
            </p>
            <p>
              Not to mention our dedication to sustainability - as a digital
              only brand we want to fight the effects of fast fashion, offering
              our community digital garments that are more creative and
              sustainable than an IRL solution.
            </p>
          </div>
          <div>
            <img src="./assets/hoodie-in-about-us.png" alt="" />
          </div>
        </div>
        <div className="button">
          <a href="https://docs.google.com/document/d/1Hz4HiJ6GcbdEkultuSQ25qGOtzTYQUc8NTkautJaw6w/edit#" className="firstscreen__btn" target="_blank" rel="noreferrer">
            <svg className="btn-svg-outline" width="229" height="52" viewBox="0 0 229 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="layer1" d="M201.273 46.4598L218.409 23.7303H225L203.909 51.7303H167L171.614 46.4598H201.273Z"></path>
              <path className="layer2" d="M38.1667 0H178.826L173.68 5.57491H194.264L199.839 0H229L197.266 39.4532H141.517L147.521 33.4494H128.652L122.219 39.4532H54.0337L60.0375 33.4494H40.3109L34.3071 39.4532H0L38.1667 0Z"></path>
              <text fontFamily="Pattanakarn" fontSize="16" x="50%" y="30%" dy=".3em" textAnchor="middle" alignmentBaseline="middle">Our Whitepaper</text>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
