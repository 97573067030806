const Mission = () => {
  return (
    <div id="mission" className="Mission">
      <div className="wrapper">
        <div className="mission-header">
          <h2>Mission</h2>
          <img src="./assets/krwn-dark.png" alt="" />
        </div>
        <div className="mission-statement">
          "Our Mission is to help build the{" "}
          <span className="font-black">future of fashion</span> which lies at
          the <span className="font-black">intersection</span> of modern
          <span className="font-black"> digital couture</span> and age-old
          fashion
          <span className="font-black"> traditions</span> and experiences"
        </div>
      </div>
    </div>
  );
};

export default Mission;
