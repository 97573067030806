const Socials = () => {
  return (
    <div id="socials" className="Socials">
      <div className="wrapper">
        <h2>KRWN With Us</h2>
        <p>
          Our brand is built with our community, and together, we can level up
          to being the best web 3 fashion house. Let's spread the KRWN movement
          together.
        </p>
        <div className="social-links">
          <div className="social-link">
            <a
              href="https://twitter.com/krwn_studio"
              className="twitter"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <h3 className="social-link-title">Twitter</h3>
                <div className="social-link-subtitle">Be the first to know</div>
              </div>
              <img src="./assets/twitter-icon.png" alt="" />
            </a>
          </div>
          <div className="social-link">
            <a
              href="https://www.instagram.com/krwn_studio/"
              className="instagram"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <h3 className="social-link-title">Instagram</h3>
                <div className="social-link-subtitle">Visualizing together</div>
              </div>
              <img src="./assets/instagram-icon.png" alt="" />
            </a>
          </div>
          <div className="social-link">
            <a
              href="https://discord.com/invite/8CY5MFBc2w"
              className="discord"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <h3 className="social-link-title">Discord</h3>
                <div className="social-link-subtitle">Join the KRWNerz</div>
              </div>
              <img src="./assets/discord-icon.png" alt="" />
            </a>
          </div>
          <div className="social-link">
            <a
              href="mailto:info@krwn.studio"
              className="email"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <h3 className="social-link-title">Email</h3>
                <div className="social-link-subtitle">Write to us</div>
              </div>
              <img src="./assets/email-icon.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
